.root {
	display: flex;
	justify-content: center;
	composes: g-grid-container from global;

	@nest :global(.hashidaysConference) & :global(.hds-link-standalone) {
		&:hover :global(.hds-link-standalone__text) {
			text-decoration: none;
			&::after {
				opacity: 1;
			}
		}
	}

	@nest :global(.hashiconfConference) & :global(.hds-link-standalone) {
		& :global(.hds-link-standalone__text) {
			background: linear-gradient(to right, var(--conferenceGradientFull));
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			text-decoration: none;
			position: relative;

			&::after {
				position: absolute;
				content: '';
				left: 0;
				bottom: -1px;
				width: 100%;
				height: 1px;
				opacity: 0;
				background: linear-gradient(to right, var(--conferenceGradientFull));
				transition: 0.25s all;
				z-index: -1;
			}
		}

		&:hover :global(.hds-link-standalone__text) {
			text-decoration: none;
			&::after {
				opacity: 1;
			}
		}
	}
}

.callout {
	--borderRadius: var(--hdsplus-spacing-02);
	--verticalPadding: var(--hdsplus-spacing-06);

	width: 100%;
	display: grid;
	grid-template-columns: 1fr;

	@media (--medium-up) {
		--verticalPadding: var(--hdsplus-spacing-05);

		grid-template-columns: minmax(0, max-content) 1fr;
	}

	@media (--large) {
		--verticalPadding: var(--hdsplus-spacing-06);
	}
}

.title {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--verticalPadding) var(--hdsplus-spacing-08);

	@media (--medium-up) {
		padding-left: var(--hdsplus-spacing-07);
		padding-right: var(--hdsplus-spacing-07);
	}

	@media (--large) {
		padding-left: var(--hdsplus-spacing-08);
		padding-right: var(--hdsplus-spacing-08);
	}
}

/* 
	Normally, we would set the border styles and `overflow: hidden` on an ancestor and the backgrounds on the title and 
	content containers (`overflow: hidden` prevents the background color from overflowing the border-radius).
	However, this component renders an absolutely positioned dropdown. If `overflow: hidden` is set on an ancestor, it will obscure
	any part of the dropdown that overflows from said ancestor. This means that the background, border and overflow styles all need to be set
	farther down the tree. We do this here with absolutely positioned child divs within the title and the content containers 
	(titleInner and contentInner).
*/

.titleInner {
	pointer-events: none;
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 1px solid var(--token-color-border-faint);
	border-bottom: unset;
	border-top-left-radius: var(--borderRadius);
	border-top-right-radius: var(--borderRadius);

	@media (--medium-up) {
		border-top-left-radius: var(--borderRadius);
		border-bottom-left-radius: var(--borderRadius);
		border-top-right-radius: unset;
		border-right: none;
		border-bottom: 1px solid var(--token-color-border-faint);
	}
}

/* 
	More CSS weirdness. Turns out that `border-faint` is somewhat transparent. This means
	that if an element has a border color of `border-faint` and a background color, the latter
	will overlap with the former and thus change the color.
*/

.titleBackground {
	height: 100%;
	background: var(--token-color-surface-primary);

	@nest :global(.hashidaysConference) & {
		background: linear-gradient(var(--conferenceGradient));
	}
	
	@nest :global(.hashiconfConference) & {
		background: linear-gradient(var(--conferenceGradient));
	}

	@nest .primary & {
		background-color: var(--token-color-surface-primary);
	}

	@nest .faint & {
		background-color: var(--token-color-surface-primary);
	}
}

.titleText {
	z-index: 1;
	font-weight: 600;

	@nest :global(.hashidaysConference) & {
		color: #fff;
	}

	@media (--medium-up) {
		font-weight: 500;
	}
}

.content {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	text-align: center;
	padding: var(--verticalPadding) var(--hdsplus-spacing-07);
	gap: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		text-align: left;
		flex-direction: row;
		padding: var(--verticalPadding) var(--hdsplus-spacing-07);
	}
}

.contentInner {
	pointer-events: none;
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 1px solid var(--token-color-border-faint);
	border-top: unset;
	border-bottom-left-radius: var(--borderRadius);
	border-bottom-right-radius: var(--borderRadius);

	@media (--medium-up) {
		border-top-left-radius: unset;
		border-bottom-left-radius: unset;
		border-top-right-radius: var(--borderRadius);
		border-left: none;
		border-top: 1px solid var(--token-color-border-faint);
	}
}

.contentBackground {
	height: 100%;
	background-color: var(--token-color-surface-faint);
}

.description {
	z-index: 1;
	composes: hds-typography-body-100 from global;
	color: var(--token-color-foreground-faint);

	@nest :global(.hashidaysConference) & {
		color: var(--token-color-foreground-strong);
	}
}
